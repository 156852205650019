import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CapabilitiesComponent } from './pages/capabilities/capabilities.component';
import { BriefcaseComponent } from './pages/briefcase/briefcase.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { TranslationsComponent } from './pages/translations/translations.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { ProfilesComponent } from './pages/profiles/profiles.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'servicios', component:  CapabilitiesComponent},
  { path: 'portafolio', component: BriefcaseComponent },
  { path: 'nosotros', component: AboutusComponent },
  { path: 'translations', component: TranslationsComponent },
  { path: 'locations', component: LocationsComponent },
  { path: 'profiles', component: ProfilesComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
