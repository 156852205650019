<app-navbar></app-navbar>
<app-social-networks class="d-none d-lg-block"></app-social-networks>
<router-outlet>
  <app-loader *ngIf="loading"></app-loader>
</router-outlet>
<app-footer></app-footer>
<!-- <ngx-spinner
  bdColor="rgba(0, 0, 0, 1)"
  template="<img style='width: 30em;' src='.\assets\icons\Progress-bar_transparente.gif' />"
>
</ngx-spinner> -->
