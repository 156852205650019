import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { LocationsDto } from '../dto/locations-dto';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {
  uri = environment.apiuri + 'profiles';
  uriactive = environment.apiuri + 'profilesactive';

  constructor(
    private httpClient: HttpClient,
    public router: Router
  ) { }


  async getall() {
    const response = await this.httpClient.get(`${this.uri}`).toPromise();
    return response;
  }

  async getactive() {
    const response = await this.httpClient.get(`${this.uriactive}`).toPromise();
    return response;
  }

  async post(locationsDto: LocationsDto): Promise<any> {
    const response = await this.httpClient.post(`${this.uri}`, locationsDto, {headers: new HttpHeaders().set('Content-Type', 'application/json')}).toPromise();
    return response;
  }

  async put(locationsDto: LocationsDto): Promise<any> {
    const response = await this.httpClient.put(`${this.uri}/${locationsDto.id}`, locationsDto, {headers: new HttpHeaders().set('Content-Type', 'application/json')}).toPromise();
    return response;
  }

  async delete(locationsDto: LocationsDto): Promise<any> {
    const response = await this.httpClient.delete(`${this.uri}/${locationsDto.id}`, {headers: new HttpHeaders().set('Content-Type', 'application/json')}).toPromise();
    return response;
  }
}
