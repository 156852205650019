import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { CapabilitiesComponent } from './pages/capabilities/capabilities.component';
import { BriefcaseComponent } from './pages/briefcase/briefcase.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { FooterComponent } from './pages/includes/footer/footer.component';
import { ContactusComponent } from './pages/includes/contactus/contactus.component';
import { NavbarComponent } from './pages/includes/navbar/navbar.component';
import { SocialNetworksComponent } from './pages/includes/social-networks/social-networks.component';
import { TranslationsComponent } from './pages/translations/translations.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { ProfilesComponent } from './pages/profiles/profiles.component';
import { InterceptorServiceService } from './services/interceptor-service.service';
import { LoaderComponent } from './pages/includes/loader/loader.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CapabilitiesComponent,
    BriefcaseComponent,
    AboutusComponent,
    FooterComponent,
    ContactusComponent,
    NavbarComponent,
    SocialNetworksComponent,
    TranslationsComponent,
    LocationsComponent,
    ProfilesComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule
  ],
  providers: [
    NavbarComponent,
    LoaderComponent,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorServiceService, multi: true }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
