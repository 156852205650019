import { Component, OnInit } from '@angular/core';

import { LocationsDto } from '../dto/locations-dto';
import { LocationsService } from './locations.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {

  viewtable: any = [];

  id: string = "";
  name: string = "";

  constructor(
    private locationsService: LocationsService
  ) { }

  ngOnInit(): void {
    this.viewalltable();
  }

  async addtext() {
    if (this.name != "") {
      let locationsDto = new LocationsDto;
      locationsDto.name = this.name;
      locationsDto.active = "1";
      let answer = "";
      if (this.id == "") {
        answer = await this.locationsService.post(locationsDto);
      } else {
        locationsDto.id = this.id;
        answer = await this.locationsService.put(locationsDto);
      }
      if (answer['status'] == "200") {
        alert("Se a cargado con exito");
        this.viewalltable();
      } else {
        alert("Se ha presentado un problema, favor de contactar al área de desarrolo");
      }
      this.clear();
    } else {
      alert("Favor de llenar todo el formulario");
    }
  }

  async clear() {
    this.name = "";
    this.id = "";
  }

  async viewalltable() {
    let answer = await this.locationsService.getall();
    if (answer['status'] == "200") {
      this.viewtable = answer['body'];
    }
  }

  async active(id) {
    let locationsDto = new LocationsDto;
    locationsDto.id = id;
    locationsDto.active = "1";
    var answer = await this.locationsService.put(locationsDto);

    if (answer['status'] == "200") {
      alert("Se a cargado con exito");
      this.viewalltable();
    } else {
      alert("Se ha presentado un problema, favor de contactar al área de desarrolo");
    }
  }

  async desactive(id) {
    let locationsDto = new LocationsDto;
    locationsDto.id = id;
    locationsDto.active = "0";
    var answer = await this.locationsService.put(locationsDto);

    if (answer['status'] == "200") {
      alert("Se a cargado con exito");
      this.viewalltable();
    } else {
      alert("Se ha presentado un problema, favor de contactar al área de desarrolo");
    }
  }

  async edit(id, name) {
    this.id = id;
    this.name = name;
  }

}
