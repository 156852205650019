import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '../translations/translations.service';
import { NavbarComponent } from '../includes/navbar/navbar.component';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  translations:any = [];
  language:string;
  loading: boolean = true;

  array_certificates:any = [
    {name: "Capability Maturity Model Integration", url_logo: "./assets/icons/certificates/ECS-CMMI3-certificacion@3x.png"},
    {name: "NORMAN NIELSEN GROUP - UX CERTIFIED", url_logo: "./assets/icons/certificates/nng-certification@3x.png"}
  ];

  array_partners:any = [
    {name: "Automation Anywhere", url_logo: "./assets/icons/certificates/automation.png", href: "https://www.automationanywhere.com/"},
    {name: "UI Path", url_logo: "./assets/icons/certificates/UiPath.png", href: "https://www.uipath.com/"},
    {name: "Soroco", url_logo: "./assets/icons/certificates/soroco_logo.svg", href: "https://soroco.com/"}
  ];
  
  constructor(
      private translationsService : TranslationsService,
      private navbarComponent : NavbarComponent) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    if(localStorage.getItem("lang") != null) {
      this.language = localStorage.getItem("lang");
    }else{
      this.language = this.navbarComponent.langSelected;
    }
    this.view();
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  
  scrollDown(el: HTMLElement) {
    el.scrollIntoView({behavior:"smooth"});
  }

  view(){
    this.translationsService.getone("4").subscribe(
      res => {
        // console.log(res);
        this.translations = res['body'];
      },
      err =>{
        console.log(err);
      }
    )
  }

  // async view(){
  //   this.translations = [];
  //   var answer = await this.translationsService.getone("4");
  //   console.log(answer);
    
  //   if(answer['status'] == "200"){
  //     this.translations = answer['body']; 
  //   }
  // }

}
