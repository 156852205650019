import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { TranslationsDto } from '../dto/translations-dto';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  uri = environment.apiuri + 'translations';
  urione = environment.apiuri + 'translations/page';

  constructor(
    private httpClient: HttpClient,
    public router: Router
  ) { }


  async getall() {
    const response = await this.httpClient.get(`${this.uri}`).toPromise();
    return response;
  }

  // async getone(id:string) {
  //   const response = await this.httpClient.get(`${this.urione}/${id}`).toPromise();
  //   return response;
  // }

  getone(id:string){
    return this.httpClient.get(`${this.urione}/${id}`);
  }

  async post(translationsDto: TranslationsDto): Promise<any> {
    const response = await this.httpClient.post(`${this.uri}`, translationsDto, {headers: new HttpHeaders().set('Content-Type', 'application/json')}).toPromise();
    return response;
  }

  async put(translationsDto: TranslationsDto): Promise<any> {
    const response = await this.httpClient.put(`${this.uri}/${translationsDto.id}`, translationsDto, {headers: new HttpHeaders().set('Content-Type', 'application/json')}).toPromise();
    return response;
  }

  async delete(translationsDto: TranslationsDto): Promise<any> {
    const response = await this.httpClient.delete(`${this.uri}/${translationsDto.id}`, {headers: new HttpHeaders().set('Content-Type', 'application/json')}).toPromise();
    return response;
  }
}
