<nav class="navbar navbar-expand-md fixed-top navbar-dark" id="nav-pf" [style.background]="color_navbar">
    <div class="container-fluid navbar-inner">
        <div data-toggle="collapse" data-target=".navbar-collapse.show" class="d-none d-sm-block img-navbar-pf" routerLink="/home">
            <img style="margin: 16px;" src="./assets/icons/{{img_to_show}}.svg" alt="">
        </div>
        <div data-toggle="collapse" data-target=".navbar-collapse.show" class="d-block d-sm-none img-navbar-pf" routerLink="/home">
            <img style="margin: 16px;" src="./assets/icons/{{img_to_show_mb}}.svg" alt="">
        </div>
        <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span class="my-1 mx-2 close"><img src="./assets/icons/menu-close.svg" alt=""></span>
            <span class="navbar-toggler-icon" [ngClass]="{'black': actual_page == briefcaseLink}"></span>
          </button>
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link dropdown-toggle border border-light d-sm-none d-block" href="#" id="nav-dropdown-lang" role="button" data-toggle="dropdown" aria-expanded="false">
                    ES
                    <img src="./assets/icons/chevron-down.svg" alt="">
                </a>
                <ul class="dropdown-menu dropdown-lang" aria-labelledby="nav-dropdown-lang">
                    <li><a class="dropdown-item" href="#">ES</a></li>
                    <li><a class="dropdown-item" href="#">EN</a></li>
                </ul>
            </li>
        </ul>
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
            <ul class="navbar-nav mb-2 mb-lg-0">
                <li data-toggle="collapse" data-target=".navbar-collapse.show" class="nav-item first-item" [routerLinkActive]="['active']">
                    <a class="nav-link" [routerLink]="servicesLink">{{ translations[2][langSelected.toLowerCase()] }}</a>
                </li>
                <li data-toggle="collapse" data-target=".navbar-collapse.show" class="nav-item" [routerLinkActive]="['active','active-blue']">
                    <a class="nav-link" [routerLink]="briefcaseLink">{{ translations[0][langSelected.toLowerCase()] }}</a>
                </li>
                <li data-toggle="collapse" data-target=".navbar-collapse.show" class="nav-item" [routerLinkActive]="['active']" style="text-align: inherit;width: fit-content;">
                    <a class="nav-link" [routerLink]="usLink">{{ translations[1][langSelected.toLowerCase()] }}</a>
                </li>
                <li data-toggle="collapse" data-target=".navbar-collapse.show" class="nav-item dropdown d-md-block d-none">
                    <a class="nav-link dropdown-toggle border border-light" href="#" id="nav-dropdown-lang" role="button" data-toggle="dropdown" aria-expanded="false">
                        {{ langSelected }}
                        <img src="./assets/icons/chevron-down.svg" alt="">
                    </a>
                    <ul class="dropdown-menu dropdown-lang" aria-labelledby="nav-dropdown-lang">
                        <li><a class="dropdown-item" (click)="changeLang('es')">ES</a></li>
                        <li><a class="dropdown-item" (click)="changeLang('en')">EN</a></li>
                    </ul>
                </li>
            </ul>
            <div class="d-block d-md-none">
                <div class="row m-0">
                    <div class="col-12 p-0 subtitle-say-hello">
                        MONTERREY, N.L HQ
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-12 p-0 text-say-hello">
                        Av. Revolución No. 643 Local 12 <br>Col. Jardín Español, Monterrey, <br>Nuevo León <br>CP.: 64820
                    </div>
                </div>
                <!-- <div class="row m-0">
                    <div class="col-12 p-0">
                        <div class="tlf-say-hello"><span>t.</span>&nbsp;+52 (81) 8104.0389</div>
                    </div>
                </div> -->
                <div class="row m-0" style="margin-top: 16px;">
                    <div class="col-12 p-0 subtitle-say-hello">
                        CDMX
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-12 p-0 text-say-hello">
                        Coahuila 14 int. 303 <br>Col. Roma Norte <br>Ciudad de México <br>CP.: 04380
                    </div>
                </div>
                <!-- <div class="row m-0">
                    <div class="col-12 p-0">
                        <div class="tlf-say-hello" style="margin-bottom: 8px;"><span>t.</span>&nbsp;+52 (55) 6267.4493</div>
                        <div class="tlf-say-hello"><span>t.</span>&nbsp;+52 (55) 6267.4494</div>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-12">
                        <br>
                        <div class="tlf-say-hello" style="margin-bottom: 8px;"><a href="mailto:contacto@playful.mx">contacto@playful.mx</a></div>
                    </div>
                </div>

                <div class="social-network-navbar">
                    <a *ngFor="let sn of social_networks" [href]="sn.href" target="_blank">
                        <img [src]="sn.img_src" alt="">
                    </a>
                </div>
                <div class="row m-0 p-sm-5 container-footer">
                    <div class="col-lg-1 col-3 img-logo-footer">
                        <img src="../../../../assets/icons/logo-footer-pf-navbar.svg" alt="">
                    </div>
                    <div class="col-lg-11 col-9">
                        <div class="container-footer-date">
                            <div class="vertical-center">
                                <p class="py-2">©2021</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</nav>