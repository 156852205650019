import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-social-networks',
  templateUrl: './social-networks.component.html',
  styleUrls: ['./social-networks.component.css']
})
export class SocialNetworksComponent implements OnInit {

  public social_networks = [
    {href: "https://www.facebook.com/playfulmx/", img_src: "./assets/icons/social-networks/FACEBOOK.svg"},
    {href: "https://twitter.com/playfulmx", img_src: "./assets/icons/social-networks/TWITTER.svg"},
    {href: "https://www.instagram.com/playfulgram/", img_src: "./assets/icons/social-networks/INSTAGRAM.svg"},
    // {href: "#behance", img_src: "./assets/icons/social-networks/BEHANCE.svg"},
    {href: "https://www.linkedin.com/company/playful-interactive/", img_src: "./assets/icons/social-networks/LINKEDIN.svg"}
  ]

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    if (window.scrollY >= 500 ) {
      this.social_networks.forEach((element, idx) => {
        var sidenav = document.getElementById('img-sidenav'+idx);
        sidenav.classList.add("sidenav-scroll");
      });
    } else {
      this.social_networks.forEach((element, idx) => {
        var sidenav = document.getElementById('img-sidenav'+idx);
        sidenav.classList.remove("sidenav-scroll");
      });
    }
  }

}
