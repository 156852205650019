import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { SpinnerServiceService } from './spinner-service.service';
import { finalize } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class InterceptorServiceService implements HttpInterceptor{

  constructor(private SpinnerServiceService: SpinnerServiceService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    this.SpinnerServiceService.showSpinner();
    return next.handle(req).pipe(
      finalize(() => {
        this.SpinnerServiceService.hideSpinner()
        setTimeout(() => {
        }, 2000);
      })
    );
  }
}
