import { Component, OnInit } from '@angular/core';

import { TranslationsDto } from '../dto/translations-dto';
import { TranslationsService } from './translations.service';

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.css']
})
export class TranslationsComponent implements OnInit {

  options: string[] = ["Home", "Capacidades", "Portafolio", "Quienes Somos", "Contactanos", "Navbar"];
  viewtable: any = [];

  id: string = "";
  es: string = "";
  en: string = "";
  page: string = "";
  viewpage:string = "";

  constructor(
    private translationsService: TranslationsService
  ) { }

  ngOnInit(): void {
    this.viewalltable();
   }

  async addtext() {
    if (this.es != "" && this.en != "" && this.page != "") {
      let translationsDto = new TranslationsDto;
      translationsDto.en = this.en;
      translationsDto.es = this.es;
      translationsDto.page = this.page == "Home"? "1" : this.page == "Capacidades" ? "2" : this.page == "Portafolio" ? "3" : this.page == "Quienes Somos" ? "4" : this.page == "Contactanos" ? "5" : "6";
      let answer = "";
      if (this.id == "") {
        answer = await this.translationsService.post(translationsDto);
      } else {
        translationsDto.id = this.id;
        answer = await this.translationsService.put(translationsDto);
      }
      if (answer['status'] == "200") {
        // alert("Se a cargado con exito");
        this.viewalltable();
      } else {
        alert("Se ha presentado un problema, favor de contactar al área de desarrolo");
      }
      this.clear();
    }else{
      alert("Favor de llenar todo el formulario");
    }
  }

  async clear() {
    this.es = "";
    this.en = "";
    this.page = "";
    this.id = "";
  }

  async viewalltable() {
    let answer = await this.translationsService.getall();
    if (answer['status'] == "200") {
      this.viewtable = answer['body'];
    }
  }

  async edit(id:string, es:string, en:string, page:string) {
    console.log(page);
    this.id = id;
    this.es = es;
    this.en = en;
    this.viewpage = this.options[parseInt(page) -1];
    this.page = page;
  }

  async delete(id:string, es:string, en:string, page:string){
    let translationsDto = new TranslationsDto;
    translationsDto.id = id;
    translationsDto.en = en;
    translationsDto.es = es;
    translationsDto.page = page == "Home"? "1" : page == "Capacidades" ? "2" : page == "Portafolio" ? "3" : page == "Quienes Somos" ? "4" : page == "Contactanos" ? "5" : "6";
    let answer = "";
    if(id !== ""){
      answer = await this.translationsService.delete(translationsDto);
      this.viewalltable();
    }
  }
}
